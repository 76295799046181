.headerContainer {
  height: 70px;
  padding-left: 80px;
}

.searchInput {
  outline: none;
  border: none;
}
.searchInput::placeholder {
  font-weight: 300;
}

.flagSelected {
  border: 2px solid #1890ff;
  transform: scale(1.2);
}
.notifIn {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 10px;
  border: 2px SOLID white;
  background-color: red;
  border-radius: 50%;
  height: 13px;
  width: 13px;
}
.bellIcon {
  position: relative;
}
